<script>

export default {

    props: {
        itemData: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            seoInsightQuery: {}
        };
    },

    methods: {

    }

}
</script>
 
<template>
    <div v-if="itemData">
        <div class="row">
            <div class="col text-center">
                {{ itemData.seoScore }}
                <p>SEO</p>
            </div>
            <div class="col text-center">
                {{ itemData.bestPracticeScore }}
                <p>Best Practices</p>
            </div>

            <div class="col text-center">
                {{ itemData.performanceScore }}
                <p>Performance</p>
            </div>
        </div>
        <div class="row">
            <div v-if="itemData.dataUrl" class="col text-center">
                <router-link :to="{ name: 'website-page-seo-insights', query: { savedResultsUrl: itemData.dataUrl } }"
                    target="_blank">
                    View Result
                </router-link>
            </div>
            <div v-else class="col text-center">
                <p>Unable to display detailed result</p>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>


           