<script>

export default {
  
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
        };
    },

    methods: {
       
    }

}
</script>
 
<template>
    <div v-if="itemData">
        <div class="row">
            <div>

                <!-- end col -->
                <div class="col">
                    <p>{{itemData.state}}</p>
                </div>
                <!-- end card -->
            </div>
            <!-- end col -->
        </div>
    </div>
</template>


           