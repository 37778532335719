<script>
import flatPickr from "vue-flatpickr-component";
import api from '@/services/secureHttps';
import Spinner from "@/components/spinner";

export default {

    components: {
        flatPickr,
        Spinner
    },

    computed: {

        displayedSerp() {
            return this.paginate(this.data);
        },

        resultQuery() {
            return this.data;

        }
    },

    watch: {
        rows: function (newValue) {
            this.data = newValue;
            this.setPages();
        }

    },


    props: {
        appId: {
            type: Number,
            required: true
        },
        orgId: {
            type: Number,
            required: true
        },
        websiteId: {
            type: Number,
            required: true
        },
        pageId: {
            type: Number,
            required: true
        },

        rows: {
            type: Array
        }
    },

    data() {
        return {
            data: [],
            page: 1,
            perPage: 10,
            pages: [],
            orderImpressionsDesc: false,
            orderClicksDesc: false,
            orderCtrDesc: false,
            orderPosition: false,
            defaultDateConfig: {
                dateFormat: "d M, Y",
                date: null
            },
            startDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
            endDate: new Date()
        };
    },

    methods: {

        paginate(data) {
            let page = this.page;
            let perPage = this.perPage;
            let from = page * perPage - perPage;
            let to = page * perPage;
            return data.slice(from, to);
        },

        setPages() {
            if (this.data === null) {
                return;
            }

            this.updatePages(this.data);
        },

        updatePages(data) {
            this.pages = [];
            let numberOfPages = Math.ceil(data.length / this.perPage);
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        },

        orderbyImpressions() {
            console.log('sorting');
            if (this.orderImpressionsDesc === false) {
                this.resultQuery.sort((a, b) => b.impressions - a.impressions);
                this.orderImpressionsDesc = true;
            } else {
                this.resultQuery.sort((a, b) => a.impressions - b.impressions);
                this.orderImpressionsDesc = false;
            }
        },

        orderbyClicks() {
            console.log("click sorting");
            if (this.orderClicksDesc === false) {
                this.resultQuery.sort((a, b) => b.clicks - a.clicks);
                this.orderClicksDesc = true;
            } else {
                this.resultQuery.sort((a, b) => a.clicks - b.clicks);
                this.orderClicksDesc = false;
            }
        },

        orderbyCtr() {
            console.log("ctr sorting");
            if (this.orderCtrDesc === false) {
                this.resultQuery.sort((a, b) => b.clickThroughRate - a.clickThroughRate);
                this.ctrDesc = true;
            } else {
                this.resultQuery.sort((a, b) => a.clickThroughRate - b.clickThroughRate);
                this.ctrDesc = false;
            }
        },

        orderbyPosition() {
            console.log("orderbyPosition sorting");
            if (this.orderPosition === false) {
                this.resultQuery.sort((a, b) => b.position - a.position);
                this.orderPosition = true;
            } else {
                this.resultQuery.sort((a, b) => a.position - b.position);
                this.orderPosition = false;
            }
        },

        filter() {
            console.log(this.startDate);
            var utcStartDate = new Date(this.startDate).toISOString()
            var utcEndDate = new Date(this.endDate).toISOString();

            var appParams = `dataType=pageserp;startDate=${utcStartDate};endDate=${utcEndDate}`;
            api.getAppData(this.appId, this.orgId, this.websiteId, this.pageId, appParams)
                .then(response => {
                    this.updatePages(response.data);
                    this.data = response.data;
                })
        }
    }

}
</script>
            
<template>
    <div>
        <div class="row">
            <div>

                <!-- end col -->
                <div class="col">
                    <div class="card card-height-100">
                        <div class="card-header">
                            <div class="row">
                                <div class="col">
                                    SERP Analyisis
                                </div>
                            </div>

                            <div class="row">
                                <div class="col">
                                    Start Date<flat-pickr v-model="startDate" :config="defaultDateConfig"
                                        class="form-control flatpickr-input">
                                    </flat-pickr>
                                </div>
                                <div class="col">
                                    End Date<flat-pickr v-model="endDate" :config="defaultDateConfig"
                                        class="form-control flatpickr-input">
                                    </flat-pickr>
                                </div>
                                <div class="col">
                                    <button v-show="index != 0" class="btn btn-light float-right mt-4 ml-4"
                                        type="button" @click="filter()">Filter
                                    </button>
                                </div>
                            </div>

                        </div>

                        <!-- end card header -->

                        <!-- card body -->
                        <div class="card-body">
                            <Spinner :isLoading="!rows"></Spinner>
                            <!-- start table -->
                            <div v-if="rows" class="card-body">
                                <div class="table-responsive table-card">
                                    <table class="table align-middle table-nowrap">
                                        <thead class="table-light text-muted">
                                            <tr>
                                                <th class="sort col-4" data-sort="currency_name" scope="col">Keyword
                                                </th>
                                                <th scope="col" @click="orderbyCtr">CTR</th>
                                                <th scope="col" @click="orderbyImpressions">Impressions</th>
                                                <th scope="col" @click="orderbyClicks">Clicks</th>
                                                <th scope="col" @click="orderbyPosition">Position</th>
                                            </tr>
                                        </thead>
                                        <!--end thead-->
                                        <tbody class="list form-check-all">
                                            <tr v-for="(data, index) of displayedSerp" :key="index">
                                                <td class="col-4 text-wrap">
                                                    {{ data.keys }}
                                                </td>
                                                <td>
                                                    {{ data.clickThroughRate }}
                                                </td>
                                                <td>
                                                    {{ data.impressions }}
                                                </td>
                                                <td>
                                                    {{ data.clicks }}
                                                </td>
                                                <td>
                                                    {{ data.position.toFixed(2) }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <!--end tbody-->
                                    </table>
                                </div>
                                <div class="d-flex justify-content-end mt-3">
                                    <div class="pagination-wrap hstack gap-2">
                                        <a class="page-item pagination-prev disabled" href="#" v-if="page != 1"
                                            @click="page--">
                                            Previous
                                        </a>
                                        <ul id="pageIndex" class="pagination listjs-pagination mb-0">
                                            <li :class="{
                                                active: pageNumber == page,
                                                disabled: pageNumber == '...',
                                            }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                                                :key="index" @click="page = pageNumber">
                                                <a class="page" href="#">{{ pageNumber }}</a>
                                            </li>
                                        </ul>
                                        <a class="page-item pagination-next" href="#" @click="page++"
                                            v-if="page < pages.length">
                                            Next
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- end card body -->
                    </div>
                    <!-- end card -->
                </div>
                <!-- end col -->
            </div>
        </div>
    </div>
</template>