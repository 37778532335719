<script>
import Spinner from "@/components/spinner";

export default {
    components: {
        Spinner
    },

    mounted() {
        console.log("url inspection mounted");
    },

    props: {
        urlData: {
            type: Object,
            required: true
        },
        isGscAppInstalled: {
            type: Boolean,
            required: true
        }
    },

    computed: {
        richResultTableData() {
            var dataTable = [];
            console.log("logging:");
            console.log(this.urlData);
            console.log(this.urlData.inspectionResult.richResultsResult);
            if (this.urlData &&
                this.urlData.inspectionResult.richResultsResult) {
                console.log("inside if");
                var richResults = this.urlData.inspectionResult.richResultsResult.detectedItems;

                richResults.forEach(x => {
                    //     console.log("inside richresults", rich);
                    var row = {
                        type: x.richResultType
                    };

                    x.items.forEach(item => {
                        //     console.log("inside item")
                        row.name = item.name;
                        item.issues.forEach(issue => {
                            row.message = issue.issueMessage;
                            row.severity = issue.severity;
                        });
                    });

                    dataTable.push(row);
                });
            }

            console.log("dataTable!!", dataTable);

            return dataTable;
        }
    },

    methods: {
        formatUtcDate(dateStr) {
            var m = new Date(dateStr);
            return m.getUTCFullYear() + "/" + (m.getUTCMonth() + 1) + "/" + m.getUTCDate() + " " + m.getUTCHours() + ":" + m.getUTCMinutes() + ":" + m.getUTCSeconds();
        },

        createGoogleSearchConsoleAccount() {
            window.open('https://search.google.com/search-console/about', '_blank');
        }
    }

}
</script>
 
<template>
    <div>
        <div v-show="isGscAppInstalled" class="row">
            <div>

                <!-- end col -->
                <div class="col">
                    <div class="card card-height-100">
                        <div class="card-header align-items-center d-flex">
                            Index Status
                        </div>
                        <!-- end card header -->

                        <!-- card body -->
                        <div class="card-body">
                            <Spinner :isLoading="!urlData"></Spinner>

                            <!-- start table -->
                            <div v-if="urlData" class="card-body">
                                <div class="row">
                                    <div class="col">
                                        <h2>Google Crawl Status</h2>

                                        <table class="table table-borded table-nowrap mb-0">
                                            <tr>
                                                <th class="no-wrap" scope="row">Coverage State </th>
                                                <td>{{ urlData.inspectionResult.indexStatusResult.coverageState }}</td>
                                            </tr>
                                            <tr>
                                                <th class="no-wrap" scope="row">Google Canonical</th>
                                                <td>{{ urlData.inspectionResult.indexStatusResult.googleCanonical }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="no-wrap" scope="row">Page Canonica</th>
                                                <td>{{ urlData.inspectionResult.indexStatusResult.userCanonical }}</td>
                                            </tr>
                                            <tr>
                                                <th class="no-wrap" scope="row">Indexing State </th>
                                                <td>{{ urlData.inspectionResult.indexStatusResult.indexingState }}</td>
                                            </tr>
                                            <tr>
                                                <th class="no-wrap" scope="row">Last Crawl time</th>
                                                <td>{{
                                                    formatUtcDate(urlData.inspectionResult.indexStatusResult.lastCrawlTime)
                                                }}</td>
                                            </tr>
                                            <tr>
                                                <th class="no-wrap" scope="row">Page fetch state</th>
                                                <td>{{ urlData.inspectionResult.indexStatusResult.pageFetchState }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                                <div v-if="urlData.inspectionResult.richResultsResult" class="row mt-4">
                                    <div class="col">
                                        <h2>Rich Snipet Results & Issues</h2>

                                        <p v-if="richResultTableData.length === 0">No Rich snippets found</p>

                                        <h5 class="no-wrap" scopw="row">Verdict</h5>
                                        <p>{{ urlData.inspectionResult.richResultsResult.verdict }}</p>


                                        <table v-if="richResultTableData.length > 0"
                                            class="table table-borded table-nowrap mb-0">
                                            <tr>
                                                <th>Type</th>
                                                <th>Name</th>
                                                <th>Message</th>
                                                <th>Severity</th>
                                            </tr>
                                            <tr v-for="(issue, index) in richResultTableData" v-bind:key="index">
                                                <td>{{ issue.type }}</td>
                                                <td>{{ issue.name }}</td>
                                                <td>{{ issue.message }}</td>
                                                <td>{{ issue.severity }}</td>
                                            </tr>

                                        </table>
                                    </div>
                                </div>
                                <div v-else class="row mt-4">
                                    <div class="col">
                                        <h2>Rich Snipet Results & Issues</h2>
                                        <p v-if="richResultTableData.length === 0">No Rich snippets found</p>
                                    </div>
                                </div>

                                <div class="row mt-4">
                                    <div class="col">
                                        <h2>Mobility Usability Result</h2>

                                        <table class="table table-borded table-nowrap mb-0">
                                            <tr>
                                                <th class="no-wrap" scope="row">Verdict </th>
                                                <td colspan="6">{{
                                                    urlData.inspectionResult.mobileUsabilityResult.verdict
                                                }}</td>
                                            </tr>
                                            <tr v-for="(issue, index) in urlData.inspectionResult.mobileUsabilityResult.issues"
                                                v-bind:key="index">
                                                <th class="no-wrap" scope="row"> Issue Type</th>
                                                <td>{{ issue.issueType }}</td>
                                                <th class="no-wrap" scope="row">Severity</th>
                                                <td>{{ issue.severity }}</td>
                                                <th class="no-wrap" scope="row">Description</th>
                                                <td>{{ issue.message }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <!-- end card body -->
                    </div>
                    <!-- end card -->
                </div>
                <!-- end col -->
            </div>
        </div>

        <div v-show="isGscAppInstalled === false" class="row">
            <div class="col">
                <div class="card card-height-100">
                    <div class="card-header align-items-center d-flex">
                        Index Status
                    </div>
                    <!-- end card header -->

                    <!-- card body -->
                    <div class="card-body">
                        <p>Google search console has not been setup for your account. Click the link below to:
                        <router-link to="/apps/integrations" class="nav-link custom-abc text-center">
                            <button type="button" class="btn btn-primary btn-sm right">
                                <i class="align-middle"></i> Integrate Google Search Console
                            </button>
                        </router-link>
                        </p>

                        <p>If you don't have a google search console account you can create a free one here:</p>
                        <div class="text-center">
                            <button type="button" class="btn btn-soft-info btn-sm right"
                                @click="createGoogleSearchConsoleAccount()">
                                <i class="align-middle"></i> Create search console account
                            </button>
                        </div>

                        <p>Benefits of integrating with google search console are:</p>
                        <ul>
                            <li>See how your page is doing on the search engine results page (SERP)</li>
                            <li>Find out which keywords are driving traffic to your site</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
