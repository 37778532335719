<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import SerpAnalysis from "@/components/searchConsole/serpAnalysis";
import UrlInspection from "@/components/searchConsole/urlInspection";
import Timeline from "@/components/timeline/pageHistory";
import TrackKeywords from "@/components/trackKeywords/trackKeywords";
import api from '@/services/secureHttps';
import { mapGetters } from 'vuex';

export default {
    mounted() {
        this.getPageInfo();
    },

    components: {
        Layout,
        PageHeader,
        SerpAnalysis,
        Timeline,
        TrackKeywords,
        UrlInspection
    },

    props: {
        title: String,
        organisationId: {
            type: Number,
            required: true
        },
        websiteId: {
            type: Number,
            required: true
        },
        pageId: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            pagePath: "",
            serpData: null,
            timelineData: null,
            gscAppId: null,
            trackKeywordsData: null,
            keywordTrackingAppId: null,
            urlInspectionData: null,
            isGscAppInstalled: true    //Google search console
        };
    },

    computed: {
        ...mapGetters('context', [
            'selectedWebisteId', 'selectedWebsiteUrl,'
        ])
    },

    methods: {
        async getPageInfo() {
            console.log("calling getPageInfo")
            api.getPageInfo(this.selectedWebisteId, this.pageId, this.organisationId)
                .then(response => {
                    console.log("getPageInfo data: ", response.data);
                    var apps = response.data.apps;
                    this.pagePath = response.data.path;

                    apps.forEach(async app => {
                        if (app.name.toLowerCase() === "page timeline") {
                            console.log("getting timeline data")
                            await api.getAppData(app.id, this.organisationId, this.websiteId, this.pageId, "")
                                .then(dataResp => {
                                    if (dataResp.data) {
                                        this.timelineData = dataResp.data;
                                    } else {
                                        console.log("no timeline data");

                                        this.timelineData = {};
                                        this.timelineData.timelineItems = [];
                                    }
                                    console.log("got timeline data :-)", dataResp.data);
                                });
                        } else if (app.name.toLowerCase() === "google search console") {
                            console.log("getting search conosle data")
                            this.gscAppId = app.id;
                            await api.getAppData(app.id, this.organisationId, this.websiteId, this.pageId, "dataType=pageserp")
                                .then(dataResp => {
                                    this.serpData = dataResp.data;
                                    console.log("got serp data :-)", dataResp.data);
                                });
                            await api.getAppData(app.id, this.organisationId, this.websiteId, this.pageId, "dataType=urlsinspection")
                                .then(dataResp => {
                                    this.urlInspectionData = dataResp.data;
                                    console.log("got url inspection data :-)", dataResp.data);
                                });

                        } else if (app.name.toLowerCase() === "track serp keywords") {
                            console.log("getting Track SERP Keywords data")
                            await api.getAppData(app.id, this.organisationId, this.websiteId, this.pageId, "")
                                .then(dataResp => {
                                    this.trackKeywordsData = dataResp.data;
                                    this.keywordTrackingAppId = app.id;
                                    console.log(" SERP Keywords data :-)", dataResp.data);
                                });
                        }

                       
                        var gscApp = apps.filter(x => {
                            return x.name.toLowerCase() === "google search console"
                        });

                        if (gscApp.length == 0) {
                            this.isGscAppInstalled = false
                        }
                    });
                });


        }
    }

}
</script>
            
<template>
    <Layout>
        <PageHeader :title="title" />
        <div class="row">
            <div class="col">
                <h2>Page: {{ pagePath }}</h2>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <UrlInspection :urlData="urlInspectionData" :isGscAppInstalled="isGscAppInstalled"></UrlInspection>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <Timeline :timelineData="timelineData"></Timeline>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <SerpAnalysis v-show="serpData" :rows="serpData" :appId="gscAppId" :orgId="organisationId"
                    :websiteId="websiteId" :pageId="pageId"></SerpAnalysis>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <TrackKeywords :keywordTrackingData="trackKeywordsData" :appId="keywordTrackingAppId"
                    :orgId="organisationId" :websiteId="websiteId" :pageId="pageId">
                </TrackKeywords>
            </div>
        </div>

    </Layout>
</template>

