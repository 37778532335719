<script>
import api from '@/services/secureHttps';

export default {
    mounted() {
    },

    components: {
    },

    computed: {

        displayedSerp() {
            return this.paginate(this.data);
        },

        resultQuery() {
            return this.data;
        }
    },

    watch: {
        keywordTrackingData: function (newValue) {
            console.log("keyword track data", newValue);
            if (typeof newValue.keywords == 'undefined') {
                this.data = []
            } else {
                this.data = newValue.keywords;

                if( this.data.length > 0) {
                    this.rowSelected(this.data[0]);
                }
            }

            this.setPages();
        }

    },

    props: {
        appId: {
            type: Number,
            required: true
        },
        orgId: {
            type: Number,
            required: true
        },
        websiteId: {
            type: Number,
            required: true
        },
        pageId: {
            type: Number,
            required: true
        },

        keywordTrackingData: {
            type: Object
        }
    },

    data() {
        return {
            data: [],
            page: 1,
            perPage: 5,
            pages: [],
            orderbyKeywordDesc: false,
            orderLocationDesc: false,
            orderLanguageDesc: false,
            newKeyowrd: "",
            selectedLanguage: "English",
            selectedLocation: "london,England,United Kingdom",
            basicLineChart: {
                series: [{
                    data: []
                }
                ],
                chartOptions: {
                    chart: {
                        height: 350,
                        type: "line",
                        zoom: {
                            enabled: false,
                        },
                        toolbar: {
                            show: false,
                        },
                    },
                    markers: {
                        size: 4,
                    },
                    dataLabels: {
                        enabled: false,
                    },
                    stroke: {
                        curve: "straight",
                    },
                    colors: this.getChartColorsArray('["--vz-primary"]'),
                    title: {
                        text: "Product Trends by Month",
                        align: "left",
                        style: {
                            fontWeight: 500,
                        },
                    },
                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: 'MMM \'yy',
                                day: 'dd MMM',
                                hour: 'HH:mm'
                            }
                        }
                    },
                },
            }
        };
    },

    methods: {

        paginate(data) {
            let page = this.page;
            let perPage = this.perPage;
            let from = page * perPage - perPage;
            let to = page * perPage;
            return data.slice(from, to);
        },

        setPages() {
            if (this.data === null) {
                return;
            }

            this.updatePages(this.data);
        },

        updatePages(data) {
            this.pages = [];
            let numberOfPages = Math.ceil(data.length / this.perPage);
            for (let index = 1; index <= numberOfPages; index++) {
                this.pages.push(index);
            }
        },

        sortAlphabetically(nameA, nameB) {
            if (nameA < nameB) //sort string ascending
                return -1;
            if (nameA > nameB)
                return 1;
            return 0; //default return value (no sorting)
        },

        orderbyKeyword() {
            console.log('sorting');
            if (this.orderbyKeywordDesc === false) {
                this.resultQuery.sort((a, b) => this.sortAlphabetically(b.keyword.toLowerCase(), a.keyword.toLowerCase()));
                this.orderbyKeywordDesc = true;
            } else {
                this.resultQuery.sort((a, b) => this.sortAlphabetically(a.keyword.toLowerCase(), b.keyword.toLowerCase()));
                this.orderbyKeywordDesc = false;
            }
        },
  
        orderbyLocation() {
            console.log("click sorting");
            if (this.orderLocationDesc === false) {
                this.resultQuery.sort((a, b) => this.sortAlphabetically(b.locationName.toLowerCase(), a.locationName.toLowerCase()));
                this.orderLocationDesc = true;
            } else {
                this.resultQuery.sort((a, b) => this.sortAlphabetically(a.locationName.toLowerCase(), b.locationName.toLowerCase()));
                this.orderLocationDesc = false;
            }
        },

        orderbyLanguage() {
            console.log("ctr sorting");
            if (this.orderLanguageDesc === false) {
                this.resultQuery.sort((a, b) => this.sortAlphabetically(b.languageName.toLowerCase(), a.languageName.toLowerCase()));
                this.orderLanguageDesc = true;
            } else {
                this.resultQuery.sort((a, b) =>  this.sortAlphabetically(a.languageName.toLowerCase(), b.languageName.toLowerCase()));
                this.orderLanguageDesc = false;
            }
        },

        trackKeyword() {
            console.log("tracking keyword");
            var cmdParams = {
                "commandType": "trackKeyword",
                "keyword": this.newKeyowrd,
                "languageName": this.selectedLanguage,
                "locationName": this.selectedLocation
            };

            api.sendAppCommand(this.appId, this.orgId, this.websiteId, this.pageId, cmdParams)
                .then(() => {
                    api.getAppData(this.appId, this.orgId, this.websiteId, this.pageId, "")
                        .then(response => {
                            this.updatePages(response.data.keywords);
                            this.data = response.data.keywords;
                        });
                });
        },

        getChartColorsArray(colors) {
            colors = JSON.parse(colors);
            return colors.map(function (value) {
                var newValue = value.replace(" ", "");
                if (newValue.indexOf(",") === -1) {
                    var color = getComputedStyle(document.documentElement).getPropertyValue(newValue);
                    if (color) {
                        color = color.replace(" ", "");
                        return color;
                    }
                    else return newValue;
                } else {
                    var val = value.split(',');
                    if (val.length == 2) {
                        var rgbaColor = getComputedStyle(document.documentElement).getPropertyValue(val[0]);
                        rgbaColor = "rgba(" + rgbaColor + "," + val[1] + ")";
                        return rgbaColor;
                    } else {
                        return newValue;
                    }
                }
            });
        },

        rowSelected(selectedItem) {
            console.log(selectedItem);
            var getPositionsCmd = {
                "commandType": "getKeywordPositions",
                "keywordInfoId": selectedItem.id
            };

            api.sendAppCommand(this.appId, this.orgId, this.websiteId, this.pageId, getPositionsCmd)
                .then(response => {
                    console.log(response);
                    //var xSeries = [];
                    //var ySeries = [];
                    console.log(' this.basicLineChart.series', this.basicLineChart.series[0]);

                    this.basicLineChart.series[0].data = [];
                    response.data.dataPoints.forEach(point => {
                        console.log(new Date(point.pointX));

                        var row = [];
                        row.push(point.pointX);
                        row.push(point.pointY);
                        this.basicLineChart.series[0].data.push(row);
                    });
                    console.log(' this.basicLineChart.series', this.basicLineChart.series[0]);
                    this.$refs.chart.updateOptions({
                        title: {
                            text: '(' + response.data.keyword.locationName +', ' + response.data.keyword.languageName + ') ' + response.data.keyword.keyword
                        }
                    });
                   // this.basicLineChart.chartOptions.title.text = response.data.keyword.locationName +', ' + response.data.keyword.languageName + ',' + response.data.keyword.keyword;
                });
        }
    }

}
</script>
            
<template>
    <div>
        <div class="row">
            <div>

                <!-- end col -->
                <div class="col">
                    <div class="card card-height-100">
                        <div class="card-header align-items-center d-flex">
                            Keyword Tracker


                        </div>

                        <!-- end card header -->

                        <!-- card body -->
                        <div class="card-body">

                            <!-- start adding new keyword-->
                            <form class="p-3" @submit.prevent="trackKeyword()">

                                <div class="row">
                                    <div class="col-2">


                                        <select v-model="selectedLanguage" class="form-select mb-3"
                                            aria-label="Select language">
                                            <option selected>English</option>
                                            <option value="1">Armenian</option>
                                            <option value="2">Greek</option>
                                            <option value="3">Spanish</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <select v-model="selectedLocation" class="form-select mb-3"
                                            aria-label="Select location">
                                            <option selected>london,England,United Kingdom</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <div class="form-group">
                                            <div class="input-group">
                                                <input v-model="newKeyowrd" type="text" class="form-control"
                                                    placeholder="Add keyword to track"
                                                    aria-label="Recipient's username" />
                                                <button class="btn btn-primary" type="submit">Track
                                                    Keyword
                                                </button>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </form>
                            <!-- end adding new keyword -->


                            <!-- start table -->
                            <div class="card-body">
                                <div class="table-responsive table-card">
                                    <table class="table align-middle table-nowrap">
                                        <thead class="table-light text-muted">
                                            <tr>
                                                <th class="sort col-4" data-sort="keyword" scope="col" @click="orderbyKeyword">Keyword
                                                </th>
                                                <th scope="col" @click="orderbyLocation">Location</th>
                                                <th scope="col" @click="orderbyLanguage">Language</th>
                                            </tr>
                                        </thead>
                                        <!--end thead-->
                                        <tbody class="list form-check-all">
                                            <tr v-for="(data, index) of displayedSerp" :key="index"
                                                @click="rowSelected(data)">
                                                <td class="col-4 text-wrap">
                                                    {{ data.keyword }}
                                                </td>
                                                <td>
                                                    {{ data.locationName }}
                                                </td>
                                                <td>
                                                    {{ data.languageName }}
                                                </td>
                                            </tr>
                                        </tbody>
                                        <!--end tbody-->
                                    </table>
                                </div>

                                <!-- start page index-->
                                <div class="d-flex justify-content-end mt-3">
                                    <div class="pagination-wrap hstack gap-2">
                                        <a class="page-item pagination-prev disabled" href="#" v-if="page != 1"
                                            @click="page--">
                                            Previous
                                        </a>
                                        <ul id="pageIndex" class="pagination listjs-pagination mb-0">
                                            <li :class="{
                                                active: pageNumber == page,
                                                disabled: pageNumber == '...',
                                            }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                                                :key="index" @click="page = pageNumber">
                                                <a class="page" href="#">{{ pageNumber }}</a>
                                            </li>
                                        </ul>
                                        <a class="page-item pagination-next" href="#" @click="page++"
                                            v-if="page < pages.length">
                                            Next
                                        </a>
                                    </div>
                                </div>
                                <!--end page index-->

                            </div>
                            <!-- end table-->

                            <div class="row">
                                <div class="col">
                                    <apexchart ref="chart" class="apex-charts" height="350" dir="ltr"
                                        :series="basicLineChart.series" :options="basicLineChart.chartOptions">
                                    </apexchart>
                                </div>
                            </div>
                        </div>
                        <!-- end card body -->
                    </div>
                    <!-- end card -->
                </div>
                <!-- end col -->
            </div>
        </div>
    </div>
</template>